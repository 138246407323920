<template>
    <div v-if="form">
        <!-- First Row -->
        
        <b-row class="justify-content-center">
            <b-media center-align class="text-center">
                <span class="font-weight-bolder">{{ form.name }}</span>
                <b-media-body>
                    <b-img :src="packImage" blank-color="#ccc" width="200" alt="placeholder" />
                </b-media-body>
            </b-media>
        </b-row>

        <div v-if="form.type == 'sticker_pack'">
            <div class="d-flex justify-content-start align-items-center">
                <b-card @click="showAddModal" text-variant="white" class="cursor-pointer bg-danger">
                    <i class="WMi-list-ul-2" />
                    Add New Sticker
                </b-card>
            </div>

            <b-row>
                <b-col cols="12" sm="4" md="4" v-for="item in form.details.stickers" :key="item.id">
                    <b-card no-body>
                        <b-card-body class="d-flex justify-content-between align-items-center">
                            <div class="truncate">
                                <span>Related emoji</span>
                                <h2 class="mb-25 ml-2 font-weight-bolder">
                                    {{ item.emoji }}
                                </h2>
                            </div>
                            <b-avatar size="120" :src="item.image" />
                        </b-card-body>
                        <div class="row justify-content-center mr-50">
                            <b-button v-b-tooltip.hover.v-success title="Edit" @click="showSticker(item.id)" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-success" class="btn-icon rounded-circle">
                                <feather-icon icon="FileTextIcon" />
                            </b-button>
                            <b-button v-b-tooltip.hover.v-danger title="Delete" @click="deleteSticker(item.id)" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle">
                                <feather-icon icon="XIcon" />
                            </b-button>
                            <b-button v-b-tooltip.hover.v-secondary title="Send to Top" @click="sendToTop(item.id)" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-secondary" class="btn-icon rounded-circle">
                                <feather-icon icon="ArrowUpIcon" />
                            </b-button>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </div>
        <!-- modals -->
        <b-modal v-model="showAddSticker" centered title="Add New Sticker" size="lg">
            <storeItemModal :stickerData="sticker" :fileFormData="fileForm" @addSticker="addNewSticker" subBtn="Add" />
            <template #modal-footer>
                <div class="w-100"></div>
            </template>
        </b-modal>
        <b-modal footer-text-variant="danger" header-text-variant="danger" v-model="showEditSticker" centered title="Edit New Sticker" size="lg" ok-only ok-title="Edit" @ok="editSticker">
            <storeItemModal :stickerData="stickerShow" :fileFormData="fileForm" @addSticker="editSticker" subBtn="Edit" />
            <template #modal-footer>
                <div class="w-100"></div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";
import router from "@/router";
import { VBTooltip, BDropdown, BDropdownItem, BModal, BFormCheckbox, BCardText, BFormInput, BAvatar, BRow, BCol, BAlert, BLink, BCard, BTabs, BTab, BCardSubTitle, BCardTitle, BCardBody, BMedia, BImg, BMediaBody, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import storeItemRepository from "@/abstraction/repository/storeRepository";
import { VEmojiPicker } from "v-emoji-picker";
import useFile from "../file/useFile";
import { makeid } from "@/utils/math";
import storeItemModal from "./storeItemModal.vue";
const RANDOM_TOKEN = makeid(50);
const repository = new storeItemRepository();
export default {
    components: {
        BDropdown,
        BDropdownItem,
        BCardText,
        BModal,
        VEmojiPicker,
        BFormInput,
        BButton,
        BAvatar,
        BMediaBody,
        BRow,
        BCol,
        BAlert,
        BLink,
        BCard,
        BTabs,
        BTab,
        BCardSubTitle,
        BCardTitle,
        BCardBody,
        BMedia,
        BImg,
        BFormCheckbox,
        storeItemModal,
    },
    directives: {
        // "b-modal": VBModal,
        "b-tooltip": VBTooltip,
        Ripple,
    },
    setup() {
        const form = ref(null);
        const sticker = ref({
            emoji: "",
            file_batch_id: RANDOM_TOKEN,
            is_public: 0,
            premium_sticker_pack_id: router.currentRoute.params.id,
        });
        const stickerShow = ref({
            emoji: "",
            file_batch_id: RANDOM_TOKEN,
            is_public: 0,
        });
        const stickerId = ref(router.currentRoute.params.id);
        const packImage = ref();
        const emojiAllowed = ref(false);
        const addSticker = ref(false);
        const showEditSticker = ref(false);
        const showAddSticker = ref(false);
        const { storeFile, fileForm } = useFile(RANDOM_TOKEN, "sticker_image", "stickers");
        const showAddModal = () => {
            showAddSticker.value = true;
        };
        onMounted(async () => {
            if (stickerId.value) {
                fillFields(stickerId.value);
            }
        });
        const fillFields = async (id) => {
            form.value = await repository.showMainStoreItem(id);
            packImage.value = form.value.thumbnail;
        };
        const emojiBtn = () => {
            emojiAllowed.value = !emojiAllowed.value;
        };
        const selectEmoji = (e) => {
            if (sticker.value.emoji == undefined) {
                sticker.value.emoji = "";
            }
            sticker.value.emoji += e.data;
        };
        const addNewSticker = async (file) => {
            if (sticker.value.thumbnail) {
                delete sticker.value.thumbnail;
            }
            if (file.file) {
                fileForm.value = file;
                await storeFile();
            }
            await repository.addNewSticker(sticker.value).then(() => {
                fillFields(stickerId.value);
                window.location.reload();
            });
            sticker.value = {
                emoji: "",
                file_batch_id: RANDOM_TOKEN,
                is_public: 0,
                pack_id: router.currentRoute.params.id,
            };
            showAddSticker.value = false;
        };
        const stickerIndex = ref();
        const showSticker = async (id) => {
            showEditSticker.value = true;
            repository.showSticker(id).then((res) => {
                stickerShow.value = res;
                console.log(res);
            });
            stickerIndex.value = id;
        };
        const editSticker = async (file) => {
            let data = {
                emoji: stickerShow.value.emoji,
                file_batch_id: RANDOM_TOKEN,
                is_active: stickerShow.value.is_active,
            };
            if (sticker.value.thumbnail) {
                delete sticker.value.thumbnail;
            }
            if (file.file) {
                fileForm.value = file;
                await storeFile();
            }
            await repository.editSticker(stickerShow.value.id, data).then(() => {
                fillFields(stickerId.value);
            });
            showEditSticker.value = false;
        };
        const deleteSticker = async (id) => {
            repository.deleteSticker(id).then(() => {
                fillFields(stickerId.value);
            });
        };
        const sendToTop = async (id) => {
            repository.stickerSendToTop(id).then(() => {
                fillFields(stickerId.value);
            });
        };
        return {
            form,
            packImage,
            emojiBtn,
            emojiAllowed,
            selectEmoji,
            fileForm,
            addSticker,
            sticker,
            addNewSticker,
            editSticker,
            showSticker,
            showEditSticker,
            showAddSticker,
            stickerShow,
            deleteSticker,
            showAddModal,
            sendToTop,
        };
    },
};
</script>

<style></style>
