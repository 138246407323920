<template>
    <div>
        <b-row class="justify-content-center my-1">
            <b-col cols="2">
                <span>Activate</span>
                <b-form-checkbox v-model="stickerData.is_public" class="mx-50 mt-25 custom-control-success" value="1" name="check-button" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
        </b-row>

        <b-row class="justify-content-center">
            <b-col cols="12" sm="4" md="6">
                <image-cropper v-model="fileForm" label="manager image" :url.sync="stickerData.thumbnail" />
                <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
            </b-col>
        </b-row>

        <b-row class="my-3 justify-content-center">
            <b-col cols="10">
                <b-form-input id="name" v-model="stickerData.emoji" placeholder="Select a Related Emoji" />
            </b-col>
            <b-col cols="2">
                <b-button @click="emojiBtn" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="outline-warning" class="btn-icon rounded-circle">
                    <feather-icon class="icon" icon="SmileIcon" />
                </b-button>
            </b-col>

            <VEmojiPicker class="emojiBox mt-1" v-if="emojiAllowed" @select="selectEmoji" />
        </b-row>
        <b-row class="justify-content-center">
            <b-button @click="$emit('addSticker', fileForm)" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="outline-warning"> {{ subBtn }} </b-button>
        </b-row>
    </div>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";
import router from "@/router";
import { BModal, BFormCheckbox, BCardText, BFormInput, BAvatar, BRow, BCol, BAlert, BLink, BCard, BTabs, BTab, BCardSubTitle, BCardTitle, BCardBody, BMedia, BImg, BMediaBody, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import stickerRepository from "@/abstraction/repository/stickerRepository";
import { VEmojiPicker } from "v-emoji-picker";
import useFile from "../file/useFile";
import { makeid } from "@/utils/math";
const RANDOM_TOKEN = makeid(50);
const repository = new stickerRepository();
export default {
    components: {
        BCardText,
        BModal,
        VEmojiPicker,
        BFormInput,
        BButton,
        BAvatar,
        BMediaBody,
        BRow,
        BCol,
        BAlert,
        BLink,
        BCard,
        BTabs,
        BTab,
        BCardSubTitle,
        BCardTitle,
        BCardBody,
        BMedia,
        BImg,
        BFormCheckbox,
    },
    directives: {
        // "b-modal": VBModal,
        Ripple,
    },
    props: {
        stickerData: Object,
        fileFormData: Object,
        subBtn: String,
    },
    setup(props) {
        const fileForm = props.fileFormData;
        const emojiAllowed = ref(false);
        // const { storeFile, fileForm } = useFile(RANDOM_TOKEN, "sticker_image", "stickers");
        const emojiBtn = () => {
            emojiAllowed.value = !emojiAllowed.value;
        };
        const selectEmoji = (e) => {
            if (props.stickerData.emoji == undefined) {
                props.stickerData.emoji = "";
            }
            props.stickerData.emoji += e.data;
        };
        const addNewSticker = async () => {
            emit("addSticker");
        };
        return {
            emojiBtn,
            emojiAllowed,
            selectEmoji,
            addNewSticker,
            fileForm,
        };
    },
};
</script>

<style></style>
